var $ = require("jquery");
require("imagesloaded");
require("TweenMax");
require("./mobile-nav.js");
require("./voyou.slideshow.min.js");
require('./../vendor/slick/slick.js');

$(function(){

  
  if($('.block-logos').length!=0){
    //require("./app-partner.js");
    partner();
  }
  if($('.block-quote').length!=0){

    $('.testimonial-wrap').slideShow('.testimonial-slide');

  }
  if($('.gallery').length>0){


    $('.gallery').imagesLoaded( function() {

      $(window).resize(function () {
    		waitForFinalEvent(function(){
    			_resize();
    		}, 500, "_resize");
    	});
    	waitForFinalEvent(function(){
    		_resize();
    	}, 500, "_resize");


  });


  }
  if($('.lightgallery').length!=0){

    var lg = require("lightgallery");

    $(".lightgallery").lightGallery();


      $('.btn-lg').click(function(e){

        $(this).next('.hidden-gallery').find('a:first-child').trigger('click');
        e.preventDefault();
      });



  }



});
function partner(){


    $('.logo-group').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows:false,
      responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1
        }
      },

    ]
    });

}

function _resize(){

	launch_slideshow();
}

function launch_slideshow() {
  $('.gallery').slideShow('.gallery-item', {
      onInitiate: function(){
        $('.gallery gallery-item').css('visibility','visible');
      },
      onResize: function(){
          this.destroy();
          $('.gallery gallery-item').css('visibility','hidden');
      }
  });

}

var waitForFinalEvent = (function () {
	var timers = {};
	return function (callback, ms, uniqueId) {
		if (!uniqueId) {
			uniqueId = "Don't call this twice without a uniqueId";
		}
		if (timers[uniqueId]) {
			clearTimeout (timers[uniqueId]);
		}
		timers[uniqueId] = setTimeout(callback, ms);
	};
})();
