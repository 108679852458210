
; $ = global.$ = require("/Users/cdurand/Documents/Sites/vilaincabot/branches/Wordpress/wp-content/themes/vilaincabot/bower_components/jquery/dist/jquery.js");
require("/Users/cdurand/Documents/Sites/vilaincabot/branches/Wordpress/wp-content/themes/vilaincabot/node_modules/gsap/src/minified/TweenMax.min.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, define, require) {
;(function (window, document, $, undefined) {
	"use strict";

	var slideShow = function(el, slides, options) {
		this.el = el;
		this.$el = $(el);
		this.options = options;
		this.target = slides;
		this.slides = this.$el.find(''+this.target+'');
		this._refreshInterval = null;
		this.currentActive = 0;
		this.inTransition = false;
		this.isWaiting = false;
		el.slideShow = this;
	};

	slideShow.prototype = {
		defaults: {
			timeout: 3000,
			autoplay: true,
			hasPager: true,
			hasArrow: true,
			debug: false,
			arrowTemplate: '<a href="" class="navigate-arrow prev"></a><a href="" class="navigate-arrow next"></a>',
			fx: "fade",
			ease: "Linear.easeNone",
			custom: {},
			maxHeight: null,
			minHeight: null,
			height: null,
			minItems: 1
		},

		debug: function(e) {
		if(this.config.debug) {
				console.log(e);
			}
		},

		init: function() {
			this.config = $.extend({}, this.defaults, this.options);

			var customInit = false;

			if(this.config.fx != "fade") {
				if(this[this.config.fx+"Init"] !== undefined) {
				var custom = this[this.config.fx+"Init"]();
					customInit = this.custom;
				}
			}

			this.debug("Min Items : " + this.config.minItems);


			this.debug("Slideshow Init" + customInit);
			/*if(s.config.fx != "fade") {
					s.debug("Calling custom fx : " + s.config.fx);
					var custom = this[s.config.fx](tmp, orientation, s.currentActive);
					s.custom;
				} else {
					s.fade(tmp);
				}*/

			if(this.slides.length > this.config.minItems) {

				this.$el.wrapInner('<div class="slideshow-container"></div>');
				this.setup();
				this.fx(this.config.fx);

				if(this.config.onInitiate) {
					this.config.onInitiate.call( this );
				}

				if( this.config.autoplay !== false) {
					this._refreshInterval = setTimeout($.proxy(this.automaticSlide, this), this.config.timeout);
				}

				return this;
			} else {
				if(this.config.onInitiate) {
					this.config.onInitiate.call( this );
				}
				return false;
			}
		},

		automaticSlide: function() {
			//this.debug("automaticSlide");
			this.slide(this);
		},

		setup: function() {
			var mH = 0;
			var addPager = (( typeof( this.config.hasPager ) === "undefined" || this.config.hasPager === true ) ? true : false);

			var addArrow = (( typeof( this.config.hasArrow ) === "undefined" || this.config.hasArrow === true ) ? true : false);


			if(addPager) {
				var tempPager = $('<div class="pager"><div class="pager-container"><div class="pager-wrapper"></div></div></div>');
				this.$el.append(tempPager);
				var pagerWrapper = $('.pager-wrapper', this.el);
			}

			$.each(this.slides, function(i, e) {
				var $this = $(e);
				mH = ($this.outerHeight(true) > mH ? $this.outerHeight(true) : mH);

				$this.data("style", $this.attr("style"));

				console.log(i);

				if ( addPager ) {
					pagerWrapper.append('<a href="" class="pager-bullet'+((i === 0) ? ' current-slide' : '')+'"></a>');
				}

				$this.css({
					position: "absolute"
				});
			});

			if(!this.config.height) {
				this.config.height = mH;
			}

			this.$el.css({
				position: "relative",
				//minHeight: (this.config.minHeight ? this.config.minHeight : ''),
				//height: (this.config.height ? this.config.height : ''),
				//maxHeight: (this.config.maxHeight ? this.config.maxHeight : '')
			});

			this.setHeight(this.config.height, this.config.maxHeight, this.config.minHeight);

			if(addArrow) {
				var tmp = $('<div class="navigate-wrapper"></div>').append(this.config.arrowTemplate);
				this.$el.append(tmp);
			}

		if(this.config.onSetupComplete) {
			this.config.onSetupComplete.call( this );
		}

			this.debug("setup done!");
		},

		setHeight: function(height, maxHeight, minHeight){
			this.$el.css({
				minHeight: (typeof minHeight === 'undefined' ? '' : minHeight),
				height: (typeof height === 'undefined' ? '' : height),
				maxHeight: (typeof maxHeight === 'undefined' ? '' : maxHeight)
			});
		},

		fx: function(f) {
			this.debug("base.fx");
			this.debug(f);

			if(this.config.fx != "fade") {
				this.debug("Calling custom fx : " + this.config.fx);
				var custom = this[this.config.fx+"Setup"]();
				this.custom;
			} else {
				TweenMax.set(this.slides.filter(':gt(0)'), {autoAlpha:0});
			}
		},

		launch: function() {
			this.debug("base.launch");
			TweenMax.delayedCall(this.config.timeout, this.slide, [this]);
		},

		fade: function(prev) {
			this.debug("FADE FX");
			var tl = new TimelineMax({paused: true, onCompleteParams: [this], onStartParams: [this],
				onStart: function(t) {
					if(t.config.onTransitionStart) {
						t.config.onTransitionStart.call( t );
					}
				},

				onComplete: function(t){
				if(t.config.autoplay !== false && t.isWaiting !== true) {
					clearTimeout(t._refreshInterval);
					t._refreshInterval = setTimeout($.proxy(t.automaticSlide, t), t.config.timeout);
				}

				$("a.pager-bullet", t.el).removeClass("current-slide").eq(t.currentActive).addClass("current-slide");

				t.inTransition = false;

				if(t.config.onTransitionComplete) {
					t.config.onTransitionComplete.call( t );
				}
			}});

			tl.add(
				TweenLite.to( this.slides.eq(prev), 1, {autoAlpha:0, ease: this.config.ease} ), "crossfade"
			);

			tl.add(
				TweenLite.to( this.slides.eq(this.currentActive), 1, {autoAlpha:1, ease: this.config.ease} ), "crossfade"
			);

			tl.play();
		},

		slide: function(s, orientation, i) {
			//s.debug("INT " + i);
			if(!s.config.destroy) {
				if( typeof( orientation ) === "undefined" || orientation === null ) orientation = false;

				if(!s.inTransition) {
					var tmp = s.currentActive;

					if(orientation != false) {
						s.currentActive = --s.currentActive % s.slides.length;
					} else {
						s.currentActive = ++s.currentActive % s.slides.length;
					}

				if( typeof( i ) !== "undefined") s.currentActive = i;

					if(s.config.fx != "fade") {
						s.debug("Calling custom fx : " + s.config.fx);
						var custom = this[s.config.fx](tmp, orientation, s.currentActive);
						s.custom;
					} else {
						s.fade(tmp);
					}
				}
			}
		},

		clicker: function(t, e, i) {
			t.debug(e);
			t.debug(t.inTransition);
			if(!$(e.target).hasClass('disabled')) {
				TweenMax.killTweensOf(t.slide);

				if(this.isWaiting !== true){
					clearTimeout(t._refreshInterval);
					}

					this.slide(t, ($(e.target).hasClass('prev') ? true : false), i);
			}
			//e.debug(t), this._refreshInterval && clearTimeout(this._refreshInterval), this.slide(e, i(t.target).hasClass("prev") ? !0 : !1)
		},

		onResize: function(t, e) {
			t.debug(e);

			t.debug("onresize");
			if(t.config.onResize) {
				t.config.onResize.call( this );
			}
		},

		onMouseHover: function(t, e) {
		t.debug(e);
			if(t.config.onMouseHover) {
				t.config.onMouseHover.call( this );
			}
		},
		onMouseLeave: function(t, e) {
		t.debug(e);
			if(t.config.onMouseLeave) {
				t.config.onMouseLeave.call( this );
			}
		}
	}

	slideShow.defaults = slideShow.prototype.defaults;

	$.extend(slideShow.prototype, {
		destroy: function() {
			this.config.destroy = true;

			$('.pager, .navigate-wrapper, .after, .before', this.$el).remove();

			this.$el.unbind();
			this.$el.removeData('slideShow');
			this.$el.removeAttr("style");

			if(this._refreshInterval) {
				clearTimeout(this._refreshInterval)
			}

			$(window).unbind('resize.slideShow');

			$(""+this.target+"", this.el).each(function(i, e){
				$(e).removeAttr("style").attr('style', $(e).data("style"));
			});

			this.$el.find('.slideshow-container').contents().unwrap();
		}
	});

	$.extend(slideShow.prototype, {
		wait: function(time) {
			this.debug("WAITING..." + time);

			this.isWaiting = true;

			this.debug(this.isWaiting);

			var hasTime = (( typeof( time ) === "undefined" || time === null ) ? false : true);
			var _time = (( typeof( time ) === "undefined" || time === null ) ? this.config.timeout : time);

			clearTimeout(this._refreshInterval);

			if(hasTime) {
				this.resume(_time)
			}
		}
	});

	$.extend(slideShow.prototype, {
		resume: function(time) {
			time = (( typeof( time ) === "undefined" || time === false ) ? this.config.timeout : time);

			this.debug("resuming..." + time);

			if(this.config.autoplay !== false) {
				clearTimeout(this._refreshInterval);
				this._refreshInterval = setTimeout($.proxy(this.automaticSlide, this), time);
				this.isWaiting = false;
			}
		}
	});

	$.fn.slideShow = function(slides, options) {
		return this.each(function(){

			//console.log("RETURN");
			//console.log(this);
			var s = (new slideShow(this, slides, options).init());
			//console.log(s);
			if(s) {
				$(".navigate-wrapper a", s.el).click(function(e) {
					s.clicker(s, e);
					return false;
				});

				$("a.pager-bullet", s.el).click(function(e) {
					s.clicker(s, e, $(this).index());
					return false;
				});

				$(s.el).hover(function(e){
					s.onMouseHover(s, e);
				}, function(e){
					s.onMouseLeave(s, e);
				});

				$(window).bind('resize.slideShow', function (e) {
					s.onResize(s, e);
				});
			}
		});
	}

	window.slideShow = slideShow;

}(window, document, jQuery));

}).call(global, module, undefined, undefined);
