

var resizeId = null,
	isMobile = $('<div id="isMobile"></div>'),
	toggleNav = $('<a href="" class="toggleNav"><i class="fa fa-bars"></i></a>');
	closeNav = $('<div class="close-menu"><a href=""><i class="fa fa-times"></i></a></div>');

$(function(){
	_init();
});

$(window).load(function(){
	onCommonResize();
});


function _init() {
	$('body').addClass('hasJS');
	$("table tr td:nth-child(even), table tr th:nth-child(even)").addClass('alt');
	$('a[rel=external]').attr('target','_blank');
	$('li:last-child').addClass('last');
	$('li:first-child').addClass('first');

	$('body').append(isMobile);

	$(window).resize(function () {
		waitForFinalEvent(function(){
			onCommonResize();
		}, 500, "commonResize");
	});

	if($('.link-hover').length > 0) {
		$('.link-hover').each(function(i){
			hoverBox = $('<div class="hover-box"></div>');
			TweenLite.set(hoverBox, {autoAlpha: 0});

			$(this).append(hoverBox);

			$(this).css({
				display: 'block',
				position: 'relative'
			}).hover(function(){
				TweenLite.to($('.hover-box', this), 1, {autoAlpha: 1});
			}, function(){
				TweenLite.to($('.hover-box', this), 1, {autoAlpha: 0});
			});
		});
	}
}

function toggleMobileMenu(){
	if ($('body').hasClass('show-nav')) {
        $('body').removeClass('show-nav');
		$('#page').unbind("click touchdown");

    } else {
        $('body').addClass('show-nav');
    }
}

function onCommonResize(){
	console.log("onCommonResize");

	$('.table-parent .is-cell, .table-parent .table-cell').removeAttr("style");

	if (!$('#isMobile').is(':visible')){
		$('.is-table').each(function(){
			table = $(this);
			$('.is-cell, .table-cell', table).css({
				height: $(table).closest('.table-parent').outerHeight(true)
			});
		});
	}

	/**-----
	/* Build off canva menu if mobile
	-----**/
	if ($('#isMobile').is(':visible') && $('.mobile-nav').length <= 0) {
		$("#main-navigation").find("ul.main-nav").clone().prependTo("#page").wrap('<div class="mobile-nav" />');
		$("#main-navigation").find("ul.language-selector").clone().appendTo(".mobile-nav");
		$("#main-navigation").find(".close-menu").clone().prependTo(".mobile-nav");

		$('.mobile-nav').find("ul.language-selector").wrap('<div class="lang-selector"></div>');
		$('.mobile-nav').find("ul.language-selector li, ul.language-selector a").unwrap();

		$('.mobile-nav').prepend(closeNav);
		$("#main-navigation .navigation").append(toggleNav);


		closeNav.click(function() {
			toggleMobileMenu();
			return false;
		});

		toggleNav.click(function() {
			toggleMobileMenu();

			$('#page').bind("click touchdown", function(){
				toggleMobileMenu();
			});

			return false;
		});
	}
}

var waitForFinalEvent = (function () {
	var timers = {};
	return function (callback, ms, uniqueId) {
		if (!uniqueId) {
			uniqueId = "Don't call this twice without a uniqueId";
		}
		if (timers[uniqueId]) {
			clearTimeout (timers[uniqueId]);
		}
		timers[uniqueId] = setTimeout(callback, ms);
	};
})();
